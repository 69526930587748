$gris-1: #dfdfdf;
$gris-2: #868686;

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #dfdfdf;
  overflow-y: scroll;
  .loader {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .contain {
      width: 300px;
      text-align: center;
      span {
        margin-bottom: 10px;
        display: inline-block;
      }
    }
  }
  header {
    .logo {
      background-color: #ec2031;
      padding-bottom: 20px;
    }
    .black-line {
      background-color: black;
      height: 60px;
      margin-bottom: 10px;
    }
    text-align: center;
    .banner {
      background-color: #3698ad;
    }
  }
  main {
    flex-grow: 1;
  }
  footer {
    flex-shrink: 0;
    .shape {
      clip-path: polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%);
      width: 100%;
      height: 100px;
      background-color: $gris-2;
      margin-bottom: -1px;
    }
    .content {
      text-align: center;
      color: white;
      background-color: $gris-2;
      padding: 40px 10px;
      span {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 12px;
        margin-right: 10px;
      }
      .card-logo {
        width: 65px;
      }
      .bank-logo {
        width: 120px;
      }
    }
  }
}
