@import url('https://fonts.googleapis.com/css?family=Lato:100,400,900&display=swap');
@import "~bootstrap/scss/bootstrap";

html,
body,
#root {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  position: fixed;
}

#root {
  overflow-y: scroll;
}

code {
  margin-top: 10px;
  overflow: auto;
  width: 300px !important;
  display: block;
  font-size: 10px;
  height: 200px !important;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

$mynewcolor: rgb(218, 58, 59);

.getInvoiceForm{
  margin: 50px 0;
}

main {
  flex-grow: 1;
}

header, main, footer {
  flex-shrink: 0;
}

//.btn-primary {
//  @include button-variant(
//    $mynewcolor,
//    darken($mynewcolor, 7.5%),
//    darken($mynewcolor, 10%),
//    lighten($mynewcolor, 5%),
//    lighten($mynewcolor, 10%),
//    darken($mynewcolor, 30%)
//  );
//}
//
//.btn-outline-primary {
//  @include button-outline-variant($mynewcolor, #222222, lighten($mynewcolor, 5%), $mynewcolor);
//}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgray !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: lightgray !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: lightgray !important;
}
